import React from "react";
import Img from "gatsby-image";
import "../../assets/main.css";
import { Link } from "gatsby";

export default ({ node, link, amount, months, daily }) => (
  <div class="w-full rounded overflow-hidden shadow grid-flow-col bg-white relative mb-5 py-2">
    <div className="xl:flex">
      <div
        style={{
          flex: 4,
        }}
      >
        <div className="xl:flex">
          <img
            alt="Placeholder"
            class="block h-auto w-full"
            style={{
              flex: 1,
              maxWidth: "100%",
              margin: "auto auto",
              height: "auto",
              padding: 20,
              width: 120,
            }}
            src={node.image.file.url}
          ></img>

          <div
            class="px-1 py-1"
            style={{
              flex: 3,
            }}
          >
            {/*<div class="text-gray-700 text-base">{node.description.description}</div>*/}

            <div
              id="row-2"
              class="flex content-around flex-wrap p-4 justify-center text-center"
            >
              <div class="w-1/3 box-border" style={{
                borderRight: '1px solid rgba(28,110,164,0.15)',
                borderLeft: '1px solid rgba(28,110,164,0.15)'
              }}>
                {" "}
                <span className="text-xl font-bold">
                  {node.minimumFico == 0 ? "-" : node.minimumFico + "+"}
                </span>{" "}
                <p class="text-xs uppercase opacity-50 mt-0 mb-1">
                  Credit Score
                </p>{" "}
              </div>
              {/* <div class="p-3"><p class="text-md font-bold">Amount</p>  ${node.loanAmounts[1].internal.content} </div> */}
              <div class="w-1/3 box-border" style={{
                borderRight: '1px solid rgba(28,110,164,0.15)'
              }}>
                {" "}
                <span className="text-xl font-bold">
                  {node.minimumTib ? node.minimumTib + " months" : "-"}
                </span>{" "}
                <p class="text-xs uppercase opacity-50 mt-0 mb-1">
                  Time in Business
                </p>{" "}
              </div>
              <div class="w-1/3 box-border" style={{
                borderRight: '1px solid rgba(28,110,164,0.15)'
              }}>
                {" "}
                <span className="text-xl font-bold">
                  {" "}
                  $
                  {node.loanAmounts[0].internal.content
                    .toString()
                    .replace(/\D/g, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>{" "}
                <p class="text-xs uppercase opacity-50 mt-0 mb-1">Min Amount</p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="px-1 py-1"
          style={{
            flex: 4,
          }}
        >
          <hr className="opacity-25 mx-4" />
          {/* <div class="text-gray-700 pl-4 text-xs my-2">{node.title}</div> */}

          {node.tagList ? (
            <></>
          ) : (
            <div className="px-4 text-sm">
              <p className="text-xs uppercase opacity-50 mt-0 mb-1">
                Overview:
              </p>
              <p className="mb-1 mt-0">
                {node.description.description}{" "}
                <Link
                  to={link}
                  style={{ textDecoration: "none", color: "green" }}
                >
                  {"Keep reading >"}
                </Link>
              </p>
            </div>
          )}
          <div class="tags w-full text-left px-4  box-border ">
            {node.tagList ? (
              node.tagList
                .slice(0, 8)
                .map((title) => (
                  <div className="inline-block text-xs rounded bg-gray-200 rounded-sm mr-1 mb-1 p-1 hover:bg-gray-300">
                    {title.title}
                  </div>
                ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          flex: 1,
        }}
      >
                <p class="text-xs uppercase opacity-50 mt-4 mb-1 text-center">
                  Cost of Funds*
                </p>
        <p className="text-center text-3xl font-black text-green-800 mt-4 mb-0 pr-0">$ {node.buyRates != null ?  (daily ? parseInt((((node.buyRates[0].content * amount)/ months / 21))) : parseInt((((4.33 * (node.buyRates[0].content * amount)/21/ months))))) : (<>-</>)} {daily ? <span className="text-sm">daily</span> : <span className="text-sm">weekly</span> }</p>
        
        <div className="xl:flex">
          <div class="px-3 py-2 my-auto mx-auto text-center">
            <Link
              to={"https://working-capital.com/apply/"}
              style={{ textDecoration: "none" }}
            >
              <button className="cursor-pointer whitespace-no-wrap border-none py-3 px-8 mt-3 bg-green-600 hover:bg-green-800 text-white font-bold mt-1 mb-0 text-center justify-center w-full">
                Check Eligibility
              </button>
            </Link>
            <div className="mt-3 text-sm text-green-600 hover:text-green-800 font-bold hover:underline hover:cursor-pointer text-center">
              <Link to={link} style={{ textDecoration: "none" }}>
                Read Review
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
