import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import styles from "./reviews.module.css";
import Layout from "../components/layout";
import ArticlePreview from "../components/article-preview-short";
import Card from "../components/compare-components/loan-description.js";
import ShortCard from "../components/compare-components/loan-description-sm.js";
import "../assets/main.css";
import Breadcrumbs from "../components/Breadcrumbs";

import { useQueryParam, NumberParam, StringParam } from "use-query-params";

import SideContent from "../components/css-components/top-image-card.js";

import Navigation from "../components/navigation";
import Container from "../components/container";
import { isNull } from "lodash";

function numberWithCommas(val, money = false) {
  if (money) val = parseFloat(val).toFixed(2);
  var returnval = null;
  if (val != null)
    returnval = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (returnval == "NaN") returnval = "0.00";

  return returnval;
}

class CompareTool extends React.Component {
   

  constructor(props) {
    super(props);
    this.amount = props.amount || 5000;
    this.fico = props.fico || 650;
    this.tib = props.TIB || 23;

    this.state = {
      amount: this.amount,
      fico: this.fico,
      ficoVisible: "750-850",
      TIB: this.tib,
      payBack: 6,
      daily: true,
    };
}
dailyChange= () => {
      this.setState(state => {
      return {
        daily: true,
      };
    });
 }

 weeklyChange= () => {
      this.setState(state => {
      return {
        daily: false,
      };
    });
 }

  handleChange(e) {
    let obj = {};

    if (e.target.name == "amount") {
      var newval = e.target.value
        .toString()
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      console.log("newval", newval);
      e.target.value = newval;
      obj[e.target.name] = parseInt(newval.split(",").join(""));
    } else {
      obj[e.target.name] = e.target.value;
    }

    this.setState(obj);
  }

  handleDropdown(e) {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title");
    const articles = get(this, "props.data.allContentfulLender.edges");
    const posts = get(this, "props.data.allContentfulLender.edges");

    const { amount, fico, payBack, TIB } = this.state;

    const mainContent = (
      <div className="lg:w-1/3 w-full">
        <div className="relative lg:sticky lg:top-0 lg:pt-16">
          

          <div className="text-left">
            <div className="flex items-baseline px-4">
              <div className="flex-1">
              <p className="text-left m-0 uppercase text-sm opacity-50">Amount Requested{" "}</p>
              </div>
              <div className="flex-1 text-xl font-bold w-full text-right">
              ${numberWithCommas(this.state.amount)} 
              </div>
            </div>
            
            <div className="relative px-3">
  
                <input type="range" min="0" max="500000" step="1" value={amount} step="1000" className="slider range w-full box-border" onChange={(e) => {
                    this.handleChange(e);
                  }} name="amount"></input>
    

            </div>
      
            {/*<div class="relative text-center shadow-lg justify-end">
              <div className="w-full">
                <input
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  name="amount"
                  class="w-full border border-solid border-4 border-gray-600 px-4 py-3 pl-6 box-border text-black"
                  placeholder="10,000"
                  value={amount
                    .toString()
                    .replace(/\D/g, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  id="grid-amount"
                />
              </div>
              <span
                className="absolute select-none opacity-50"
                style={{
                  top: 8,
                  left: 11,
                }}
              >
                $
              </span>
            </div>*/}
          </div>

          <div class="text-left mt-4">

            <div className="flex items-baseline px-4">
              <div className="flex-1">
              <p className="text-left m-0 uppercase text-sm opacity-50">Payback Term{" "}</p>
              </div>
              <div className="flex-1 text-xl font-bold w-full text-right uppercase">
              {payBack} <span className="text-sm opacity-50">months</span>
              </div>
            </div>
            <div class="relative px-3 justify-end">
              
              <input type="range" min="1" max="18" value={payBack} className="slider range w-full box-border text-center" onChange={(e) => {
                    this.handleChange(e);
                  }} name="payBack"></input>
    
              
              {/*<select
                onChange={(e) => {
                  this.handleChange(e);
                }}
                value={payBack}
                name="payBack"
                class="block appearance-none w-full bg-white border border-solid border-4 border-gray-600 text-black py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-payback"
              >
                <option value="0"></option>
                <option value="3">3 Months</option>
                <option value="6">6 Months</option>
                <option value="12">12 Months</option>
                <option value="18">18 Months</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>*/}
            </div>
          </div>
          <div className="shadow mt-8 p-2">
            <h4 className="text-center opacity-75 text-bold uppercase my-2">
              Business Qualifications
            </h4>
            <div class="text-center p-3">
              Personal Credit Score{" "}
              <div class="relative text-center shadow-lg justify-end">
                <select
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  value={fico}
                  name="fico"
                  class="block appearance-none w-full bg-white border border-solid border-4 border-gray-600 text-black py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-fico"
                >
                  <option value="400">400-449</option>
                  <option value="450">450-549</option>
                  <option value="550">550-649</option>
                  <option value="650">650-749</option>
                  <option value="750">750+</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

            <div class="text-center p-3">
              Time in Business{" "}
              <div class="relative text-center shadow-lg justify-end">
                <select
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  value={TIB}
                  name="TIB"
                  class="block appearance-none w-full bg-white border border-solid border-4 border-gray-600 text-black py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-tib"
                >
                  <option value="5">Less than 6 months</option>
                  <option value="11">6 months to a year</option>
                  <option value="23">1-2 years</option>
                  <option value="30">2+ years</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    const getPosts = () => {
      let arr = [];
      posts.forEach(({ node }) => {
        console.log(JSON.stringify(node.minimumFico));
        if (
          this.state.amount <= Number(node.loanAmounts[1].internal.content) &&
          this.state.amount >= Number(node.loanAmounts[0].internal.content) &&
          node.minimumFico < this.state.fico &&
          (node.loansTerms[0].internal.content <= this.state.payBack ||
            node.loansTerms[1].internal.content >= this.state.payBack) &&
          node.minimumTib < this.state.TIB
        ) {
          arr.push(node);
        }
      });
      return arr;
    };

    const sideContent = (
      <div className="lg:w-2/3 w-full">
        <div className="flex inline-flex grid grid-cols-1 m-2">
          
          <p className="text-xs uppercase opacity-50 mt-0 mb-4 pl-4">{getPosts().length} lenders found</p>
          <div className="text-center lg:text-right inline-block pb-4">
          <span className="text-xs uppercase opacity-20 mt-0 mb-4 pl-4">How do you want to pay?</span>
              <button onClick={this.dailyChange} className="cursor-pointer whitespace-no-wrap border-none py-3 px-8 mt-3 bg-gray-600 hover:bg-gray-800 text-white font-bold mt-1 mb-0 mr-1 ml-3 text-center justify-center w-1/8">
                Daily
              </button>
              <button onClick={this.weeklyChange} className="cursor-pointer whitespace-no-wrap border-none py-3 px-8 mt-3 bg-gray-600 hover:bg-gray-800 text-white font-bold mt-1 mb-0 ml-1 text-center justify-center w-1/8">
                Weekly
              </button>
          </div>
          {getPosts() && getPosts().length > 0 ? (
            getPosts().map((node) => {
              console.log(JSON.stringify(node));

              return (
                <div className="">
                  <div className=" h-full">
                    <Card node={node} daily = {this.state.daily} link={`/reviews/${node.slug}`} amount={this.state.amount} months={this.state.payBack}/>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="mx-auto">
              <p>No lenders found. Please try using different values. </p>
            </div>
          )}
           <p className="text-xs uppercase opacity-50 mt-0 mb-4 pl-4">*Cost of funds is an estimation based on example rates.</p>
        </div>
      </div>
    );

    return (
      <div style={{ background: "#fff" }}>
        {" "}
        {/* Taking this out ruins navbar color for some reason */}
        <Navigation />
        <div className="bg-gray-600 w-full">
          <div
            class="relative z-0 text-center rounded shadow-md flex card justify-center"
            style={{ zIndex: "1", justify: "center" }}
          >
            <div
              class="bg-cover h-72 rounded-l-sm"
              alt="Room Image"
              style={{
                height: "120px",
                background: `url(${require("../assets/img/ready-hero.jpg")})`,
                backgroundPosition: "center",
                filter: "brightness(30%)",
                backgroundSize: "cover",
                width: "100%",
              }}
            ></div>

            <div class="absolute content-center bottom-0 hover:opacity-100">
              <h1 className="text-center text-2xl py-3 px-0 text-white font-bold mt-1 mb-">
                Compare Business Lenders
              </h1>
            </div>
          </div>
        </div>
        <Breadcrumbs url={this.props.location.pathname} />
        <Container>
          <div className="lg:flex">
            {mainContent}

            {sideContent}
          </div>
        </Container>
      </div>
    );
  }
}

const Parent = () => {
  // something like: ?x=123&foo=bar in the URL
  //const [amount, setAmount] = useQueryParam("amount", NumberParam);
  //const [fico, setFico] = useQueryParam("fico", StringParam);

  return (
    <div>
      <CompareTool amount={"3000"} fico={"560"} />
    </div>
  );
};

export default CompareTool;

export const pageQuery = graphql`
  query CompareToolQuery {
    allContentfulLender(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          tagList {
            title
          }

          description {
            description
          }
          title
          slug
          image {
            file {
              url
            }
          }
          buyRates{
            content  
		  }

          minimumFico
          minimumTib
          loansTerms {
            internal {
              content
            }
          }

          loanAmounts {
            internal {
              content
            }
          }
        }
      }
    }
  }
`;
